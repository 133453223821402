var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"s36VCAplJunaBLvtryM03"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

function getSentryEnv() {
	if (process.env.NODE_ENV === 'production') {
		return {
			environment: 'production',
			tracesSampleRate: 0.1,
		};
	}
	if (process.env.NODE_ENV === 'test') {
		return {
			environment: 'test',
			tracesSampleRate: 0.1,
		};
	}
	return {
		environment: 'local',
		tracesSampleRate: 1.0,
	};
}

export const SentryMonitoring = {
	init: (pageTransaction) => {
		Sentry.init({
			environment: getSentryEnv().environment,
			dsn: SENTRY_DSN,
			integrations: [
				new Sentry.BrowserTracing({
					beforeNavigate: (context) => ({
						...context,
						name: pageTransaction,
					}),
				}),
			],
			tracesSampleRate: getSentryEnv().tracesSampleRate,
			sendClientReports: false,
		});
	},
};
